var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper",attrs:{"data-testid":"partnerShip-card"}},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Add New Partnership "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCategoryFormalidate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(validate)}}},[_c('b-row',_vm._l((_vm.addCategoryForm.fields),function(field,index){return _c('b-col',{key:index,staticClass:"border rounded p-2 mb-2",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select Category"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"partnerShip-category-select","options":_vm.categoriesList,"label":"type","reduce":function (val) { return val.id; },"placeholder":"Select a category"},model:{value:(field.category_id),callback:function ($$v) {_vm.$set(field, "category_id", $$v)},expression:"field.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-media-aside',[_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":field.imagePreview,"height":"110","width":"170"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"rounded ml-1 mt-2 text-center",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.removeImage(index)}}},[_vm._v(" Remove Image ")])],1),_c('b-media-body',[_c('b-card-text',{staticClass:"mt-1"}),_c('div',{staticClass:"d-inline-block mt-1"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Upload Image"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"partnerShip-image","accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"change":function (e) { return _vm.inputImageRenderer(e, index); }},model:{value:(field.image),callback:function ($$v) {_vm.$set(field, "image", $$v)},expression:"field.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"partnerShip-name","placeholder":"Name"},model:{value:(field.name),callback:function ($$v) {_vm.$set(field, "name", $$v)},expression:"field.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Link"}},[_c('validation-provider',{attrs:{"rules":"url","name":"Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"partnerShip-link","vmodel":"field.link","placeholder":"Link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(index != 0)?_c('b-button',{staticClass:"mx-1",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.removeField(index)}}},[_vm._v("-")]):_vm._e(),(_vm.addCategoryForm.fields.length-1 == index && index!=3)?_c('b-button',{attrs:{"size":"sm","id":"butt","variant":"primary","disabled":_vm.addCategoryForm.fields.length >= 4},on:{"click":_vm.addField}},[_vm._v("+")]):_vm._e()],1)],1)],1)}),1),_c('b-row',[_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[(!_vm.isLoading)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"id":"partnerShip-submit-button","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")]):_vm._e(),(_vm.isLoading)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":""}},[_vm._v(" Loading... "),_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"sr-only"})],1):_vm._e()],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }